import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { amountToString, gt, zeroAmount } from '@orus.eu/amount'
import type { RenewalDetails } from '@orus.eu/backend/src/services/contract-endorsement/contract-renewal-service'
import { formatDdMmYyyy } from '@orus.eu/calendar-date'
import { nbsp } from '@orus.eu/char'
import {
  Avatar,
  Button,
  Callout,
  colorTokens,
  ContractPanel,
  Divider,
  emoji,
  PersistentNotification,
  spacing,
  Text,
  useUiContext,
  type ContractPanelContent,
} from '@orus.eu/pharaoh'
import { cssPropsPerScreenVariantPerTextVariant, type TextCssProps } from '@orus.eu/pharaoh/src/components/atoms/text'
import { useNavigate } from '@tanstack/react-router'
import { memo, useCallback } from 'react'
import { getPaymentMethodDisplayName } from '../../../lib/payment-method-util'
import {
  RenewalButtonContainer,
  RenewalContainer,
  RenewalLeftBlockLayout,
  RenewalPageLayout,
  TextWithBottomSpacing,
} from './RenewalLayout'

type RenewalSuccessStateProps = {
  isLocked: boolean
  renewalDetails: RenewalDetails
  goToStartState: () => void
  isBackoffice?: boolean
}

export const RenewalSuccessState = memo<RenewalSuccessStateProps>(function RenewalSuccessState(
  props: RenewalSuccessStateProps,
): JSX.Element {
  const { isLocked, renewalDetails } = props
  const uiContext = useUiContext()
  const typography = cssPropsPerScreenVariantPerTextVariant['body2'][uiContext]

  const navigate = useNavigate()

  const navigateToPaymentMethodUpdate = useCallback(() => {
    void navigate({ to: '/payment-methods/new' })
  }, [navigate])

  const quote = renewalDetails.quote
  const paymentRecurrence = renewalDetails.paymentRecurrence

  const contractGridRows: ContractPanelContent[] =
    renewalDetails.blockedStatus !== 'not-blocked'
      ? [
          { type: 'row', title: 'Date de renouvellement', values: [formatDdMmYyyy(renewalDetails.effectDate)] },
          {
            type: 'row',
            title: 'Date du nouveau tarif',
            values: [formatDdMmYyyy(renewalDetails.effectDate)],
            withBottomSpacing: true,
          },
          {
            type: 'row',
            title: 'Nouveau tarif',
            values: quote
              ? [
                  paymentRecurrence === 'yearly'
                    ? `${amountToString(quote.yearlyTotalPremium, { addCurrency: true, displayDecimals: 'when-amount-has-cents' })} par an`
                    : `${amountToString(quote.subsequentMonthsTotalPremium, { addCurrency: true, displayDecimals: 'when-amount-has-cents' })} par mois`,
                ]
              : [],
          },
          {
            type: 'node',
            children: (
              <Divider
                orientation="horizontal"
                css={css`
                  margin-top: ${spacing[60]};
                  margin-bottom: ${spacing[60]};
                `}
              />
            ),
          },
          {
            type: 'node',
            children: (
              <>
                <Text
                  variant="body2Medium"
                  css={css`
                    margin-bottom: ${spacing[30]};
                  `}
                >
                  Détails
                </Text>
              </>
            ),
          },
          {
            type: 'row',
            title: 'Nouvelle déclaration (CA & ETP)',
            values: [
              `+${amountToString(renewalDetails.premiumAmountUpdateForPaymentRecurrence ?? zeroAmount, {
                addCurrency: true,
                displayDecimals: 'when-amount-has-cents',
              })} par ${paymentRecurrence === 'yearly' ? 'an' : 'mois'}`,
            ],
          },
          {
            type: 'row',
            title: 'Pénalité de retard',
            values:
              renewalDetails.penaltiesAmountForPaymentRecurrence &&
              gt(renewalDetails.penaltiesAmountForPaymentRecurrence, zeroAmount)
                ? [
                    `+${amountToString(renewalDetails.penaltiesAmountForPaymentRecurrence ?? zeroAmount, {
                      addCurrency: true,
                      displayDecimals: 'when-amount-has-cents',
                    })} par ${paymentRecurrence === 'yearly' ? 'an' : 'mois'}`,
                  ]
                : ['Aucune'],
          },
        ]
      : [{ type: 'row', title: 'Date de renouvellement', values: [formatDdMmYyyy(renewalDetails.effectDate)] }]

  const DisplayedPersistentNotification = function DisplayedPersistentNotification(): JSX.Element | undefined {
    if (isLocked && renewalDetails.hasPenalties) return <OverchargeNotification />

    if (!isLocked) return <NoPriceNotification />

    return undefined
  }

  return (
    <RenewalPageLayout>
      <RenewalContainer gridTemplateColumns="minmax(327px, 532px) minmax(0, 508px)">
        <RenewalLeftBlockLayout>
          <Avatar src={emoji.circleCheck} size="60" />
          <Text variant="h5" color={colorTokens['color-text-base-main']}>
            Votre contrat a été renouvelé !
          </Text>
          <div>
            <TextWithBottomSpacing variant="body2" color={colorTokens['color-text-base-basic']}>
              {isLocked
                ? "En l'absence d'une validation de votre part une pénalité a dû être appliquée et votre contrat a été renouvelé pour une année supplémentaire."
                : 'Votre contrat a été renouvelé avec succès pour une année supplémentaire, merci de votre confiance.'}
            </TextWithBottomSpacing>
            <OList typography={typography}>
              <li>
                <Text variant="body2" color={colorTokens['color-text-base-basic']}>
                  {isLocked
                    ? 'Vous recevrez bientôt un email de confirmation avec un récapitulatif des informations mises à jour et de votre nouvelle cotisation.'
                    : 'Vous recevrez bientôt un email de confirmation avec un récapitulatif des informations mises à jour et de votre nouveau tarif.'}
                </Text>
              </li>
              <li css={{ marginTop: spacing[20] }}>
                <Text variant="body2" color={colorTokens['color-text-base-basic']}>
                  Votre nouvelle attestation d&apos;assurance vous sera envoyée le jour du renouvellement.
                </Text>
              </li>
            </OList>
          </div>
          {renewalDetails.paymentMethod && (
            <Callout
              variant="incentive"
              title="Vérifier votre moyen de paiement"
              avatar={<Avatar src={emoji.creditCard} />}
              actionButton={{
                label: 'Remplacer maintenant',
                withAvatar: false,
                onClick: navigateToPaymentMethodUpdate,
              }}
            >
              <Text variant="body2" color={colorTokens['color-text-decorative-main']}>
                {getPaymentMethodDisplayName(renewalDetails.paymentMethod)} est-il valide ? Mettez-le à jour en cas de
                changement.
              </Text>
            </Callout>
          )}
        </RenewalLeftBlockLayout>

        <div>
          <ContractPanel
            title={`Votre assurance RC${nbsp}Décennale`}
            contractGridRows={contractGridRows}
            PersistentNotification={<DisplayedPersistentNotification />}
            buttomButton={
              renewalDetails.blockedStatus && (
                <Button
                  variant="text"
                  avatar={<Avatar icon="circle-info-regular" />}
                  href="https://orus-team.notion.site/Comprendre-ma-hausse-tarifaire-12d0ab24de31802fa1d8d90ceba1010d"
                  avatarPosition="left"
                >
                  <Text variant="button">Plus de précisions</Text>
                </Button>
              )
            }
          />
        </div>

        {(renewalDetails.blockedStatus === 'not-blocked' || props.isBackoffice) && (
          <RenewalButtonContainer alignItems="flex-start">
            <Button
              variant="secondary"
              icon="pen-regular"
              avatarPosition="left"
              size={uiContext === 'mobile' ? 'large' : 'medium'}
              onClick={props.goToStartState}
            >
              Modifier mes informations
            </Button>
            <Text variant="caption" color={colorTokens['color-text-base-basic']}>
              Vous pouvez modifier vos informations de renouvellement jusqu&apos;au{' '}
              {formatDdMmYyyy(renewalDetails.lastClientPossibleUpdateDate)}.
            </Text>
          </RenewalButtonContainer>
        )}
      </RenewalContainer>
    </RenewalPageLayout>
  )
})

const OList = styled.ol<{ typography: TextCssProps }>`
  margin-bottom: ${spacing[40]};
  padding-left: ${spacing[50]};
  color: ${colorTokens['color-text-base-basic']};
  font-size: ${({ typography }) => typography.fontSize};
  line-height: ${({ typography }) => typography.lineHeight};
  font-weight: ${({ typography }) => typography.fontWeight};
  font-family: ${({ typography }) => typography.fontFamily};
  li: {
    margin-bottom: ${spacing[10]};
  }
`

const OverchargeNotification = function OverchargeNotification(): JSX.Element {
  return (
    <PersistentNotification variant="danger">
      <Text variant="body2">
        En l&apos;absence d&apos;une validation de votre part une pénalité a dû être appliquée.
      </Text>
    </PersistentNotification>
  )
}

const NoPriceNotification = function NoPriceNotification(): JSX.Element {
  return (
    <PersistentNotification variant="info" title="Nous calculons votre tarif">
      <TextWithBottomSpacing variant="body2" color={colorTokens['color-text-base-main']}>
        Patience, les détails de votre nouveau tarif seront disponibles sous peu, généralement deux mois avant la date
        d&apos;échéance.
      </TextWithBottomSpacing>
      <Text variant="body2" color={colorTokens['color-text-base-main']}>
        Ils seront également affichés sur cette page.
      </Text>
    </PersistentNotification>
  )
}
