import styled from '@emotion/styled'
import { desktopMediaQuery, spacing, Text } from '@orus.eu/pharaoh'

export const RenewalContainer = styled.div<{ gridTemplateColumns: string }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill);
  gap: ${spacing[70]};
  margin: ${spacing[60]} auto ${spacing[100]};
  max-width: 760px;
  padding: 0 ${spacing[60]};

  ${desktopMediaQuery} {
    grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
    grid-template-rows: repeat(2, auto);
    column-gap: ${spacing[100]};
    margin: ${spacing[80]} auto;
    padding: 0 ${spacing[60]};
    max-width: 1168px;
  }
`

export const RenewalPageLayout = styled.div`
  height: 100%;
  overflow-y: auto;
`

export const RenewalLeftBlockLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[70]};
`

export const RenewalButtonContainer = styled.div<{ alignItems: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${spacing[80]};
  gap: ${spacing[40]};

  & > button {
    width: 100%;
  }

  ${desktopMediaQuery} {
    align-items: ${({ alignItems }) => alignItems};

    & > button {
      width: auto;
    }
  }
`

export const TextWithBottomSpacing = styled(Text)`
  margin-bottom: ${spacing[20]};
`
